import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import history from './common/history';
import './css/styles.css'

class ErrorPage extends React.Component {
  render() {
    return (
      <div>
        Error!
      </div>
    )
  }
}

export default ErrorPage;
